import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-index-apps',
  templateUrl: './index-apps.component.html',
  styleUrls: ['./index-apps.component.css']
})

/***
 * Apps Component
 */
export class IndexAppsComponent implements OnInit {

  constructor(private titleService: Title,
              private metaService: Meta) { }

  /***
   * Client Review Owl Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  ngOnInit(): void {
    this.titleService.setTitle('Surplus | Track Your Expenses and Subscriptions');
    this.metaService.updateTag({
      name: 'description', content: 'Always know how much you have left to spend with Surplus, an app that helps you keep track of your expenses and recurring payments'
    });
  }
}
