import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-privacy',
  templateUrl: './page-privacy.component.html',
  styleUrls: ['./page-privacy.component.css']
})

/**
 * PAge Privacy Component
 */
export class PagePrivacyComponent implements OnInit {

  constructor(private titleService: Title,
              private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Privacy Policy - Surplus');
    this.metaService.updateTag({
      name: 'description', content: 'Please read Our Privacy Policy carefully before using Our Service.'
    });
  }

}
