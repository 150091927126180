<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Press Kit </h4>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->
<section class="section pt-4 pb-0">
    <div class="container">
        <div class="row p-3">
            <h4>What is surplus?</h4>

            <p class="text-muted">Surplus is a monthly expense tracker that provides you with a clear overview of your leftover spend.</p>

            <p class="text-muted">It was designed for people who have trouble following strict budgets due to impulse driven spending habits. </p>

            <p class="text-muted">Surplus takes a bottom line approach to budgeting by factoring current and upcoming expenses to provide an up-to-date tracker of your leftover spend. The app supports a variety of transaction types making it easy to keep tabs on installments and subscriptions. Each transaction is mapped to either a need, want or savings bucket to help gain insight into where your money is being spent.</p>

            <p class="text-muted">Surplus will help you make better decisions when it comes to purchases and manage situations where you find yourself overspending.</p>

            <h4>Highlighted Features:</h4>
            <ul class="text-muted">
                <li><b>Track Subscriptions & Installments:</b> You can add a variety of recurring purchases that are automatically accounted for in your leftover spend.</li>
                <li><b>Fast & Convenient Expense Recording:</b> Add and categorize transactions quickly and efficiently. Launch transaction entry flows right from widgets on your home screen.</li>
                <li><b>Supports all Currencies:</b> Choose any currency and format combination.</li>
                <li><b>Sync Your Accounts:</b> Link accounts from over 10,000 supported institutions in the US & Canada. Surplus will automatically categorize your transactions and remember to apply any adjustments you make to future transactions.</li>
                <li><b>Privately stored & synced:</b> No account creation is required. All personal and transaction data is stored locally on your devices and synced privately via iCloud.</li>
                <li><b>Advanced Reports:</b> Explore your current, historic and upcoming purchases via interactive customizable  reports. Export them in CSV format to integrate with other tools.</li>
                <li><b>Widgets:</b> Turn your home screen into a useful dashboard of your financial information.</li>
            </ul>

            <h4>Media</h4>

            <p class="text-muted">High resolution media images showcasing various features of the app can be downloaded <a class="link-light" href="https://storage.googleapis.com/surplus-media-kit/SurplusMediaKit.zip">here</a></p>

            <h4>Pricing</h4>

            <p class="text-muted">Surplus is free to use with some features requiring Surplus Pro which can be purchased as one time purchase or via a subscription. Purchase offers and pricing are available in the app.</p>

            <h4>Availability</h4>

            <p class="text-muted">Surplus is an iOS and iPadOS app that is available in all territories supported by the App Store. The app can be downloaded <a class="link-light" href="https://apps.apple.com/us/app/surplus-expense-tracker/id1578612881">here</a></p>

        </div>
    </div>
</section>

<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
   [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
