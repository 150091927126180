import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import {BNPLProvider} from '../bnpl-home/bnpl-home.component';
import {TitleCasePipe} from '@angular/common';
import BNPLJson from '../../../../assets/data/bnpl_providers.json';
import {map} from 'rxjs/internal/operators';

@Component({
  selector: 'app-bnpl-details',
  templateUrl: './bnpl-details.component.html',
  styleUrls: ['./bnpl-details.component.css'],
  providers: [TitleCasePipe]
})
export class BnplDetailsComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private metaService: Meta,
              private titleCaseService: TitleCasePipe) { }

  provider?: BNPLProvider;

  private providers: BNPLProvider[] = BNPLJson;

  ngOnInit(): void {
    const lookup = this.route.snapshot.paramMap.get('name');
    const name = this.titleCaseService.transform(lookup.split('-').join(''));
    this.provider = this.providers.find(e => e.name === name);
    this.titleService.setTitle('Buy Now Pay Later ' + this.provider.name + ' - Surplus');
    this.metaService.updateTag({
      name: 'description', content: 'Surplus can help you track your ' + this.provider.name + ' payments. Surplus helps you track your Buy Now Pay Later purchases and subscriptions.'
    });
  }

}
