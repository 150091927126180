import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {LightboxModule} from 'ngx-lightbox';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {NgbDropdownModule, NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {NgxTypedJsModule} from 'ngx-typed-js';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';
import {FlatpickrModule} from 'angularx-flatpickr';
import {CountToModule} from 'angular-count-to';
import {NgxMasonryModule} from 'ngx-masonry';

import {SharedModule} from './shared/shared.module';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {IndexAppsComponent} from './core/components/index-apps/index-apps.component';
import {PageErrorComponent} from './core/components/page-error/page-error.component';
import {PagePrivacyComponent} from './core/components/page-privacy/page-privacy.component';
import {PageTermsComponent} from './core/components/page-terms/page-terms.component';
import {WidgetComponent} from './core/components/widget/widget.component';
import {FeatherModule} from 'angular-feather';

import {allIcons} from 'angular-feather/icons';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';
import {MasterPageComponent} from './core/components/master-page/master-page.component';
import {ScrollspyDirective} from './shared/scrollspy.directive';
import { PressKitComponent } from './core/components/press-kit/press-kit.component';
import { BnplHomeComponent } from './core/components/bnpl-home/bnpl-home.component';
import { BnplDetailsComponent } from './core/components/bnpl-details/bnpl-details.component';
import { PlaidAuthComponent } from './core/components/plaid-auth/plaid-auth.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IndexAppsComponent,
    PagePrivacyComponent,
    PageTermsComponent,
    PageErrorComponent,
    WidgetComponent,
    MasterPageComponent,
    PressKitComponent,
    BnplHomeComponent,
    BnplDetailsComponent,
    PlaidAuthComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    NgxYoutubePlayerModule,
    NgbDropdownModule,
    CKEditorModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    SwiperModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgxMasonryModule,
    LightboxModule,
    SharedModule
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
