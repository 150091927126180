<section class="bg-half" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
                <div class="section-title">
                    <div class="text-center">
                        <h4 class="title mb-4">We Can Help Keep Track of Your {{ provider?.name}} Payments</h4>
                    </div>
                    <p class="text-muted mb-0 mt-4">Surplus is an expense tracker app that helps you track recurring expenses including {{ provider?.name }} payments and subscriptions.</p>

                    <p class="text-muted mb-0 mt-4">You can add your transactions in the app, specifying the number of remaining installments along with the payment schedule.</p>
                    <div class="text-center">
                        <a href="https://apps.apple.com/app/apple-store/id1578612881?pt=1784260&ct=BNPL&mt=8"><img src="assets/images/surplus/bnpl_surplus.png" class="img-fluid rounded-md mt-4" alt=""></a>
                    </div>
                    <p class="text-muted mb-0 mt-4">Surplus will automatically calculate and keep track of your upcoming expenses, giving you an up-to-date amount of your leftover spend for the remainder of the month.</p>
                    <div class="mt-4 text-center">
                        <a href="https://apps.apple.com/app/apple-store/id1578612881?pt=1784260&ct=BNPL&mt=8" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> Download on the App Store</a>
                    </div>
                    <p class="text-muted mb-0 mt-4">By accounting for upcoming payments from previous {{ provider?.name }} spending, you should be in a position to make better decisions when assessing purchases and avoid overspending.</p>

                    <h4 class="my-4">How Keep Track of Your {{provider?.name}} Payments:</h4>
                    <ul class="list-group text-muted m-3">
                        <li>
                            When adding a transaction, tap on the "Repeat" option.
                        </li>
                        <li>
                            Tap on "Installment Payment" when presented with the "Frequency Type" selection screen.
                        </li>
                        <li>
                            Set the "Frequency" to match your payment schedule.
                        </li>
                        <li>
                            Enter the number of transactions remaining on the payment.
                        </li>
                        <li>
                            Tap on "Done".
                        </li>
                    </ul>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
