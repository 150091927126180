import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-terms',
  templateUrl: './page-terms.component.html',
  styleUrls: ['./page-terms.component.css']
})

/***
 * Page terms Component
 */
export class PageTermsComponent implements OnInit {

  constructor(private titleService: Title,
              private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Terms of Service - Surplus');
    this.metaService.updateTag({
      name: 'description', content: 'Please read these terms and conditions carefully before using Our Service.'
    });
  }

}
