import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import BNPLJson from '../../../../assets/data/bnpl_providers.json';

export interface BNPLProvider {
  name: string;
  website: string;
  logo: string;
}

@Component({
  selector: 'app-bnpl-home',
  templateUrl: './bnpl-home.component.html',
  styleUrls: ['./bnpl-home.component.css']
})


export class BnplHomeComponent implements OnInit {

  constructor(private titleService: Title,
              private metaService: Meta) { }

  providers: BNPLProvider[] = BNPLJson;

  ngOnInit(): void {
    this.titleService.setTitle('Buy Now Pay Later - Surplus');
    this.metaService.updateTag({
      name: 'description', content: 'Surplus is an app that helps you track recurring expenses including Buy Now Pay Later (BNPL) payments and subscriptions.'
    });
  }

  localUrl(provider: BNPLProvider): string {
    return 'buy-now-pay-later/' + provider.name.split(' ').join('-').toLowerCase();
  }
}
