<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Privacy Policy </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Updated:</span> July 15, 2021</li>
          </ul>
          <p>Please read Our Privacy Policy carefully before using Our Service.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Interpretation and Definitions</h5>

            <h6>Interpretation</h6>

            <p class="text-muted">The words of which the initial letter is capitalized have meanings defined under the following
              conditions. The following definitions shall have the same meaning regardless of whether they
              appear in singular or in plural.</p>

            <h6>Definitions</h6>

            <p class="text-muted">For the purposes of these Terms of Use:</p>

            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Application</b> means the software program provided by the Company downloaded by You on any
                electronic device, named Surplus
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Application Store</b> means the digital distribution service operated and developed by Apple
                Inc. (Apple App Store) in which the Application has been
                downloaded.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a
                party, where "control" means ownership of 50% or more of the shares, equity interest or
                other
                securities entitled to vote for election of directors or other managing authority.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Country</b> refers to: Florida, United States
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers
                to Dev Harvest LLC, 3901 Nw 79th Ave Suite 245 #2021, Miami, Florida 33166.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital  tablet.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Service</b> refers to the Application or the Website or both.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Terms of Use</b> (also referred as "Terms") mean these Terms of Use that form the entire
                agreement between You and the Company regarding the use of the Service.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Third-party Social Media Service</b> means any services or content (including data, information,
                products or services) provided by a third-party that may be displayed, included or made
                available by the Service.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Website</b> refers to Surplus, accessible from surplus.app
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>You</b> means the individual accessing or using the Service, or the company, or other legal
                entity on behalf of which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>

            <h5 class="card-title">General</h5>

            <p class="text-muted">Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of
              Your personal information when You use the Application or the Website and tells You about Your
              privacy rights and how the law protects You</p>

            <h5 class="card-title">Personal Information</h5>

            <p class="text-muted">We do not collect any personal information about You. All financial information and data is
              stored securely on your Device(s) and synced via iCloud using CloudKit. Surplus does not store your financial information
              on Our servers</p>

            <h5 class="card-title">Debugging Information</h5>

            <p class="text-muted">We can automatically collect debuggable information about how our users use the Service. This
              information does not include any personal information but can include usage and device
              information such as user agents, IP addresses, installation UUIDs, crash traces, operating
              system, date/time stamp, mobile Device ID, device specifications, referring URLs, country and
              location. This information is needed to maintain the security and operation of the Service and
              used internally for analytic and reporting purposes.</p>

            <h5 class="card-title">Cookies and Other Tracking Technologies</h5>

            <p class="text-muted">We may use automated data collection tools such as Cookies. Cookies are small files that are
              placed on Your computer, mobile device or any other device by a website, containing details of
              your browsing history on that website among its many uses. We may use Cookies for monitoring and
              analyzing web traffic and for information security purposes. If You prefer to avoid the use of
              Cookies on the Website, first You must disable the use of Cookies in your browser and then
              delete the Cookies saved in your browser associated with this website. You may use this option
              for preventing the use of Cookies at any time.</p>

            <h5 class="card-title">Information Security</h5>

            <p class="text-muted">All information transmitted via the Service is securely encrypted using
              the appropriate technical security measures designed to protect any sensitive information We
              process. However, please be aware that no security system or measure is completely secure. As a
              result, we cannot guarantee the absolute security of our databases or the information you supply
              to Us or that which is stored on your device. We are not responsible for preventing unauthorized
              access to your Device. You are responsible for you keeping your Device safe and secure.</p>

            <h5 class="card-title">Links to Other Websites</h5>

            <p class="text-muted">Our Service may contain links to third-party web sites or services that are not owned or
              controlled by the Company.</p>

            <p class="text-muted">The Company has no control over, and assumes no responsibility for, the content, privacy
              policies, or practices of any third party web sites or services. You further acknowledge and
              agree that the Company shall not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection with the use of or reliance on
              any such content, goods or services available on or through any such web sites or services.</p>

            <p class="text-muted">We strongly advise You to read the terms and conditions and privacy policies of any third-party
              web sites or services that You visit.</p>

            <h5 class="card-title">Changes to Our Privacy Policy</h5>

            <p class="text-muted">We reserve the right, at Our sole discretion, to modify or replace this Privacy Policy at any
              time. Any changes or modifications to this Privacy Policy will be provided to you via the
              Service. What constitutes a material change will be determined at Our sole discretion.</p>

            <h5 class="card-title">Contact Us</h5>

            <p class="text-muted">If you have any questions about Our Privacy Policy, You can contact us:</p>
            <ul class="text-muted">
              <li>By email: info@devharvest.com</li>
            </ul>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
   [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
