import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IndexAppsComponent} from './core/components/index-apps/index-apps.component';
import {MasterPageComponent} from './core/components/master-page/master-page.component';
import {PageErrorComponent} from './core/components/page-error/page-error.component';
import {PagePrivacyComponent} from './core/components/page-privacy/page-privacy.component';
import {PageTermsComponent} from './core/components/page-terms/page-terms.component';
import {PressKitComponent} from './core/components/press-kit/press-kit.component';
import {BnplHomeComponent} from './core/components/bnpl-home/bnpl-home.component';
import {BnplDetailsComponent} from './core/components/bnpl-details/bnpl-details.component';
import {PlaidAuthComponent} from './core/components/plaid-auth/plaid-auth.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexAppsComponent },
      { path: 'index', component: IndexAppsComponent },
      { path: 'privacy', component: PagePrivacyComponent },
      { path: 'terms', component: PageTermsComponent },
      { path: 'press-kit', component: PressKitComponent },
      { path: 'buy-now-pay-later',
        children: [
          {
            path: '',
            component: BnplHomeComponent
          },
          {
            path: ':name',
            component: BnplDetailsComponent
          }
      ]},
      { path: 'plaid',
        children: [
          {
            path: '**',
            component: PlaidAuthComponent
          },
        ]
      },
    ]
  },
  { path: '404', component: PageErrorComponent },
  { path: '**', redirectTo: '/404' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
