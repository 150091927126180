<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/web_logo.png" height="24" alt="">
                        </a>
                        <p class="mt-4 text-muted">A simplified approach to managing your monthly finances.</p>
                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="https://twitter.com/GetSurplus" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Usefull Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/terms" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Terms of Services</a></li>
                            <li><a routerLink="/privacy" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/web_logo.png" height="24" alt="">
                        </a>
                        <p class="mt-4">A simplified approach to managing your monthly finances.</p>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item ms-1"><a href="https://twitter.com/GetSurplus" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Useful Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a href="https://help.surplus.app" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                FAQs & Tutorials</a></li>
                            <li><a href="https://medium.com/@surplus-app" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                Blog</a></li>
                            <li><a routerLink="/buy-now-pay-later" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                Buy Now Pay Later</a></li>
                            <li><a routerLink="/press-kit" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                Press Kit</a></li>
                        </ul>
                    </div>
                    <!--end col-->
                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Legal</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/terms" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                Terms of Services</a></li>
                            <li><a routerLink="/privacy" class="text-foot"><i
                                    class="uil uil-angle-right-b me-1"></i>
                                Privacy Policy</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </ng-template>
</div>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">© {{year}}
                        Dev Harvest LLC
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->
