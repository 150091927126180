<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
  <div class="container">
    <div class="row mt-2 align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading">
          <h1 class="heading mb-3">Stay on top of your spend with <span class="text-primary">Surplus</span></h1>
          <p class="para-desc text-muted">An expense tracker that takes into account your monthly income, expenses and upcoming payments to provide you with a clear overview of your leftover spend.</p>
          <div class="mt-4">
            <a href="https://apps.apple.com/app/apple-store/id1578612881?pt=1784260&ct=Surplus%20Landing%20Page&mt=8" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> Download on the App Store</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4">
          <img src="assets/images/app/surplus-home.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
