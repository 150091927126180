<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/web_logo.png" class="l-dark logo-light-mode" height="24" alt="">
        <img src="assets/images/web_logo.png" class="logo-dark-mode" height="24" alt="">
      </a>

      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="24" alt="">
      </a>
    </div>
    <!--end login button-->

<!--    <div class="menu-extras">-->
<!--      <div class="menu-item">-->
<!--        &lt;!&ndash; Mobile menu toggle&ndash;&gt;-->
<!--        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">-->
<!--          <div class="lines">-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--          </div>-->
<!--        </a>-->
<!--        &lt;!&ndash; End mobile menu toggle&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->

    <div id="navigation">
      <ul class="navigation-menu" [class]="navClass">
        <li><a target="_blank" href="https://medium.com/@surplus-app" class="nav-link-ref">Blog</a></li>
        <li><a routerLink="/press-kit" class="nav-link-ref">Press Kit</a></li>
      </ul>
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
