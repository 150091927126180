<div class="back-to-home rounded d-none d-sm-block">
    <a routerLink="/index" class="btn btn-icon btn-soft-primary">
        <i-feather name="home" class="icons"></i-feather>
    </a>
</div>

<!--PAGE -->
<section class="bg-home d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 text-center">
                <div class="text-capitalize text-dark mb-4 error-page">Surplus Account Link</div>
                <p class="text-muted para-desc mx-auto">You likely landed on this page while trying to link your account. If you have the app installed, please return to the Surplus App.</p>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- PAGE -->

