<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Terms of Services </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Updated:</span> October 1, 2021</li>
          </ul>
          <p>Please read these terms and conditions carefully before using Our Service.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h5 class="card-title">Interpretation and Definitions:</h5>
            <h6>Interpretation</h6>
            <p class="text-muted">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <h5 class="card-title">Definitions:</h5>
            <p class="text-muted">For the purposes of these Terms of Use:</p>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Application</b> means the software program provided by the Company downloaded by You on any electronic device, named Surplus
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Application Store</b> means the digital distribution service operated and developed by Apple Inc. (Apple App Store) in which the Application has been downloaded.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Country</b> refers to: Florida, United States
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Dev Harvest LLC, 3901 Nw 79th Ave Suite 245 #2021, Miami, Florida 33166.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Feedback</b> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Free Trial</b> refers to a limited period of time that may be free when purchasing a Subscription.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Service</b> refers to the Application or the Website or both.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Subscriptions</b> refer to the services or access to the Service offered on a subscription basis by the Company to You.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Terms of Use</b> (also referred as "Terms") mean these Terms of Use that form the entire agreement between You and the Company regarding the use of the Service.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Third-party Social Media Service</b> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>Website</b> refers to Surplus, accessible from surplus.app
              </li>
              <li>
                <i-feather name="chevron-right" class="fea icon-sm mr-2"></i-feather>
                <b>You</b> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
            </ul>

            <h5 class="card-title">Acknowledgment</h5>
            <p class="text-muted">These are the Terms of Use governing the use of this Service and the agreement that operates
              between You and the Company. These Terms of Use set out the rights and obligations of all users
              regarding the use of the Service.</p>

            <p class="text-muted">Your access to and use of the Service is conditioned on Your acceptance of and compliance with
              these Terms of Use. These Terms of Use apply to all visitors, users and others who access or use
              the Service.</p>

            <p class="text-muted">By accessing or using the Service You agree to be bound by these Terms of Use. If You disagree
              with any part of these Terms of Use then You may not access the Service.</p>

            <p class="text-muted">You represent that you are over the age of 18. The Company does not permit those under 18 to use
              the Service.</p>

            <p class="text-muted">Your access to and use of the Service is also conditioned on Your acceptance of and compliance
              with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures
              on the collection, use and disclosure of Your personal information when You use the Application
              or the Website and tells You about Your privacy rights and how the law protects You. Please read
              Our Privacy Policy carefully before using Our Service.</p>

            <p class="text-muted">Plaid is a software provider for the Company that provides a platform that allows Us to connect
              your bank accounts and other financial accounts. By agreeing to the Company’s Terms of Use and
              Privacy Policy and linking a financial institution via Plaid, you also agree to Plaid’s End User
              Privacy Policy and any Plaid relevant End User Service Agreements available at
              https://plaid.com/legal</p>

            <h5 class="card-title">Services Description</h5>

            <p class="text-muted">The Surplus Service is a personal finance application that provides you with tools to organize
              and track financial transactions both manually and or across various connected financial institutions.</p>

            <p class="text-muted">Surplus adopts a "Freemium" model where a set of Services are free to use, while some more advanced Services
            require a Surplus Pro purchase. Surplus Pro can be purchased as a one time payment or as a recurring subscription. Current pricing and purchase offers are available in the Application.</p>

            <p class="text-muted">Services may differ for Users depending on their location. The Company, in its sole discretion and at any time, may modify the scope of Services.</p>

            <p class="text-muted">Surplus is not a financial advisor, financial planner, bank or tax advisor. Neither Surplus or
              the Service are intended to provide financial, legal or tax advice. The Service is intended to
              only provide you with tools to organize and track your financial information. By using the
              Service, you agree that your personal financial situation is unique and that you should consider
              seeking the advice of a financial adviser or other financial planning professional who is aware
              of your personal financial situation before acting on any information provided by the
              Service</p>

            <h5 class="card-title">Registration Information</h5>

            <p class="text-muted">In order to use the Services, you will need a Device that is capable of downloading and
              installing the mobile application from the Application Store.</p>

            <p class="text-muted">Surplus uses iCloud and CloudKit for syncing settings and data privately between your Devices. No user accounts are created or required.</p>

            <p class="text-muted">Please refer to our Privacy Policy for more information about our data collection practices.</p>

            <p class="text-muted">You are responsible for keeping your Devices that have downloaded and installed the Services safe
              and secure.</p>

            <h5 class="card-title">Subscriptions</h5>

            <h6>Subscription period</h6>

            <p class="text-muted">Some parts of the Service are available only with a paid Subscription. You will be
              billed in advance on a recurring and periodic basis (such as daily, weekly, monthly or
              annually), depending on the type of Subscription plan you select when purchasing the
              Subscription.</p>

            <p class="text-muted">At the end of each period, Your Subscription will automatically renew under the exact same
              conditions unless You cancel it or the Company cancels it.</p>

            <h6>Subscription cancellations</h6>

            <p class="text-muted">You may cancel Your Subscription renewal either through Your Account Settings in the Application Store or by
              contacting the Company. You will not receive a refund for the fees You already paid for Your
              current Subscription period and You will be able to access the Service until the end of Your
              current Subscription period.</p>

            <h6>Fee Changes</h6>

            <p class="text-muted">The Company, in its sole discretion and at any time, may modify the Subscription fees. Any
              Subscription fee change will become effective at the end of the then-current Subscription
              period.</p>

            <p class="text-muted">The Company will provide You with reasonable prior notice of any change in Subscription fees to
              give You an opportunity to terminate Your Subscription before such change becomes effective.</p>

            <p class="text-muted">Your continued use of the Service after the Subscription fee change comes into effect constitutes
              Your agreement to pay the modified Subscription fee amount.</p>

            <h6>Free Trial</h6>

            <p class="text-muted">The Company may, at its sole discretion, offer a Subscription with a Free trial for a limited
              period of time.</p>

            <p class="text-muted">You may be required to enter Your billing information in order to sign up for the Free trial.</p>

            <p class="text-muted">If You do enter Your billing information when signing up for a Free Trial, You will not be
              charged by the Company until the Free trial has expired. On the last day of the Free Trial
              period, unless You cancelled Your Subscription, You will be automatically charged the applicable
              Subscription fees for the type of Subscription You have selected.</p>

            <p class="text-muted">At any time and without notice, the Company reserves the right to (i) modify the terms and
              conditions of the Free Trial offer, or (ii) cancel such Free trial offer.</p>

            <h5 class="card-title">Refunds</h5>

            <p class="text-muted">Except when required by law, Purchases and or Subscription fees are non-refundable.</p>

            <p class="text-muted">Certain refund requests may be considered by the Company on a case-by-case
              basis and granted at the sole discretion of the Company.</p>

            <h5 class="card-title">Intellectual Property</h5>

            <p class="text-muted">The Service and its original content (excluding Content provided by You or other users), features
              and functionality are and will remain the exclusive property of the Company and its
              licensors.</p>

            <p class="text-muted">Our trademarks and trade dress may not be used in connection with any product or service without
              the prior written consent of the Company.</p>

            <h5 class="card-title">Your Feedback to Us</h5>

            <p class="text-muted">You assign all rights, title and interest in any Feedback You provide the Company. If for any
              reason such assignment is ineffective, You agree to grant the Company a non-exclusive,
              perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose,
              sub-license, distribute, modify and exploit such Feedback without restriction.</p>

            <h5 class="card-title">Links to Other Websites</h5>

            <p class="text-muted">Our Service may contain links to third-party web sites or services that are not owned or
              controlled by the Company.</p>

            <p class="text-muted">The Company has no control over, and assumes no responsibility for, the content, privacy
              policies, or practices of any third party web sites or services. You further acknowledge and
              agree that the Company shall not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection with the use of or reliance on
              any such content, goods or services available on or through any such web sites or services.</p>

            <p class="text-muted">We strongly advise You to read the terms and conditions and privacy policies of any third-party
              web sites or services that You visit.</p>

            <h5 class="card-title">Termination</h5>

            <p class="text-muted">You may cancel your agreement at any time using the directions below:</p>
            <ul class="text-muted">
              <li>Delete the application off your Device(s)</li>
            </ul>

            <p class="text-muted">We may terminate or suspend Your access immediately, without prior notice or liability, for any
              reason whatsoever, including without limitation if we believe that:</p>
            <ul class="text-muted">
              <li>You breach these Terms of Use.</li>
              <li>We ar required to do so by law.</li>
              <li>You are delinquent in the payment of applicable fees for the Service.</li>
              <li>For any other reason at any time.</li>
            </ul>

            <p class="text-muted">Upon termination, Your right to use the Service will cease immediately.</p>

            <p class="text-muted">You acknowledge that and agree that We shall not be liable to you for any termination of your
              access to the Services</p>

            <h5 class="card-title">Limitation of Liability</h5>

            <p class="text-muted">Notwithstanding any damages that You might incur, the entire liability of the Company and any of
              its suppliers under any provision of this Terms and Your exclusive remedy for all of the
              foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if
              You haven't purchased anything through the Service.</p>

            <p class="text-muted">To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers
              be liable for any special, incidental, indirect, or consequential damages whatsoever (including,
              but not limited to, damages for loss of profits, loss of data or other information, for business
              interruption, for personal injury, loss of privacy arising out of or in any way related to the
              use of or inability to use the Service, third-party software and/or third-party hardware used
              with the Service, or otherwise in connection with any provision of this Terms), even if the
              Company or any supplier has been advised of the possibility of such damages and even if the
              remedy fails of its essential purpose.</p>

            <p class="text-muted">Some states do not allow the exclusion of implied warranties or limitation of liability for
              incidental or consequential damages, which means that some of the above limitations may not
              apply. In these states, each party's liability will be limited to the greatest extent permitted
              by law.</p>

            <h5 class="card-title">"AS IS" and "AS AVAILABLE" Disclaimer</h5>

            <p class="text-muted">The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without
              warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its
              own behalf and on behalf of its Affiliates and its and their respective licensors and service
              providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise,
              with respect to the Service, including all implied warranties of merchantability, fitness for a
              particular purpose, title and non-infringement, and warranties that may arise out of course of
              dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
              the Company provides no warranty or undertaking, and makes no representation of any kind that
              the Service will meet Your requirements, achieve any intended results, be compatible or work
              with any other software, applications, systems or services, operate without interruption, meet
              any performance or reliability standards or be error free or that any errors or defects can or
              will be corrected.</p>

            <p class="text-muted">Without limiting the foregoing, neither the Company nor any of the company's provider makes any
              representation or warranty of any kind, express or implied: (i) as to the operation or
              availability of the Service, or the information, content, and materials or products included
              thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy,
              reliability, or currency of any information or content provided through the Service; or (iv)
              that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are
              free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful
              components.</p>

            <p class="text-muted">Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
              applicable statutory rights of a consumer, so some or all of the above exclusions and
              limitations may not apply to You. But in such a case the exclusions and limitations set forth in
              this section shall be applied to the greatest extent enforceable under applicable law.</p>

            <h5 class="card-title">Governing Law:</h5>

            <p class="text-muted">The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your
              use of the Service. Your use of the Application may also be subject to other local, state,
              national, or international laws.
              Disputes Resolution</p>

            <p class="text-muted">If You have any concern or dispute about the Service, You agree to first try to resolve the
              dispute informally by contacting the Company.</p>

            <h5 class="card-title">Severability and Waiver</h5>

            <h6>Severability</h6>

            <p class="text-muted">If any provision of these Terms is held to be unenforceable or invalid, such provision will be
              changed and interpreted to accomplish the objectives of such provision to the greatest extent
              possible under applicable law and the remaining provisions will continue in full force and
              effect.</p>

            <h6>Waiver</h6>

            <p class="text-muted">Except as provided herein, the failure to exercise a right or to require performance of an
              obligation under this Terms shall not effect a party's ability to exercise such right or require
              such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver
              of any subsequent breach.</p>

            <h5 class="card-title">Changes to These Terms of Use</h5>

            <p class="text-muted">We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. Any
              changes or modifications to these Terms will be provided to you via the Service. What
              constitutes a material change will be determined at Our sole discretion.</p>

            <p class="text-muted">By continuing to access or use Our Service after those revisions become effective, You agree to
              be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please
              stop using the website and the Service.</p>

            <h5 class="card-title">Contact Us</h5>
            <p class="text-muted">If you have any questions about these Terms of Use, You can contact us:</p>
            <ul class="text-muted">
              <li>By email: info@devharvest.com</li>
            </ul>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
   [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
