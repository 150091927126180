import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-press-kit',
  templateUrl: './press-kit.component.html',
  styleUrls: ['./press-kit.component.css']
})
export class PressKitComponent implements OnInit {

  constructor(private titleService: Title,
              private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Press Kit - Surplus');
    this.metaService.updateTag({
      name: 'description', content: 'Surplus is a monthly expense tracker that provides you with a clear overview of your leftover spend.'
    });
  }

}
